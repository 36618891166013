.py-05em {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1em {
  margin-top: 1em !important;
}

.mt-3em {
  margin-top: 2em !important;
}

.my-1em {
  margin-top: 1em !important;
  margin-bottom: 1em !important;
}

.m-0 {
  margin: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-05 {
  margin-bottom: 0.5rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.h-full-vh {
  height: 100vh !important;
}

.w-full-vw {
  width: 100vw !important;
}

.space {
  margin-top: 1em;
}

.p-side {
  padding: 0.4rem;
  width: 100%;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.error-field {
  color: red;
  text-align: left;
  font-size: 14px;
  width: 100%;
}

.password-pills {
  display: flex;
  align-items: center;
}

.w-100 {
  width: 100% !important;
}

.cursor-pointer {
  cursor: pointer;
}

.social-login {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* justify-content: space-between; */
}

.social-login div {
  cursor: pointer;
  pointer-events: all;
}

.social-area {
  width: 70%;
  display: flex;
  margin: auto;
}

/* Image Blinker */
.blink-image {
  -moz-animation: blink normal 2s infinite ease-in-out; /* Firefox */
  -webkit-animation: blink normal 2s infinite ease-in-out; /* Webkit */
  -ms-animation: blink normal 2s infinite ease-in-out; /* IE */
  animation: blink normal 2s infinite ease-in-out; /* Opera and prob css3 final iteration */
}

@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* Chrome */
@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* IE */
@-ms-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* End Image Blinker */

/* Opera and prob css3 final iteration */
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* End Image Blinker */

/* Float Button Dark mode */
.float {
  position: fixed;
  width: 50px;
  height: 50px;
  cursor: pointer;
  /* bottom: 40px; */
  top: 64px;
  right: 0px;
  /* border-radius: 50px; */
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  opacity: 0.3;
}

.float:hover {
  box-shadow: 0px 0px 20px rgb(192, 192, 192);
  opacity: 0.9;
  transition: ease-in-out 0.3s;
}

.dark-mode-color {
  background-color: #0c9;
  color: #fff;
}

.light-mode-color {
  background-color: #fff;
  color: #fff;
}

.dark-mode-icon {
  height: auto;
  width: 50%;
}

/* End Float button dark mode  */

/* data table styles */
.table-cell {
  display: flex;
  align-items: center;
}

.d-inline-table {
  display: inline-table !important;
}
.ac-chart-container {
  height: "100%";
}
